<template>
  <Form
    v-slot="{ errors, meta }"
    :validation-schema="validationSchema"
    class="space-y-5"
    ref="form"
    @submit="handleSubmit"
  >
    <slot name="errors">
      <Message
        v-for="message in formErrors"
        :closable="false"
        severity="error"
      >
        {{ message }}
      </Message>
    </slot>

    <template v-if="hasTabs">
      <Tabs value="0">
        <TabList>
          <Tab
            v-for="(field, index) in schema.fields"
            :key="index"
            :value="String(index)"
          >
            {{ field.label }}
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel
            v-for="(field, index) in schema.fields"
            :key="index"
            :value="String(index)"
          >
            <div class="space-y-5">
              <slot
                v-for="{ as, name, rules, ...attrs } in field.fields"
                :as="as"
                :name="`fieldset-${name}`"
                :rules="rules"
                v-bind="{
                  loading: props.loading,
                  name,
                  ...attrs
                }"
              >
                <DynamicField
                  v-bind="attrs"
                  :as="as ? as : 'InputText'"
                  :disabled="props.loading || props.disabled"
                  :inputId="`id_${name}`"
                  :invalid="!!errors[name]"
                  :name="name"
                  @change="(value) => emit('field-change', name, value)"
                />
              </slot>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </template>

    <template v-else>
      <slot
        v-for="{ as, name, rules, ...attrs } in props.schema.fields"
        :as="as"
        :name="`fieldset-${name}`"
        :rules="rules"
        v-bind="{
          loading: props.loading,
          name,
          ...attrs
        }"
      >
        <DynamicField
          v-if="name"
          v-bind="attrs"
          :as="as ? as : 'InputText'"
          :disabled="props.loading || props.disabled"
          :inputId="`id_${name}`"
          :invalid="!!errors[name]"
          :name="name"
          @change="(value) => emit('field-change', name, value)"
        />
      </slot>
    </template>

    <div class="flex items-center justify-between">
      <slot name="submit" :props="props.schema.submit">
        <Button
          v-bind="props.schema.submit"
          :disabled="loading || !meta.valid || props.disabled"
          :label="props.schema?.submit?.label ?? 'Submit'"
          :loading="loading"
          type="submit"
        />
      </slot>

      <slot name="extra-buttons"></slot>
    </div>
  </Form>
</template>

<script lang="ts" setup>
import { Form } from 'vee-validate'
import type { ButtonProps } from 'primevue/button'

interface FieldSchema extends Object {
  label: string
  name?: string
  as?: string | Component
  rules?: any
  fields?: FieldSchema[]
  [key: string]: any
}

interface FormSchema extends Object {
  fields: FieldSchema[]
  submit?: ButtonProps
}

const emit = defineEmits<{
  'field-change': [name: string, value: any],
  submit: [values: any]
}>()
const form = ref<typeof Form>()
const formErrors = ref<string[]>([])

const props = withDefaults(defineProps<{
  disabled?: boolean,
  hideSubmit?: boolean,
  loading?: boolean
  schema: FormSchema
}>(), {
  disabled: false,
  hideSubmit: false,
  loading: false,
})

defineOptions({
  inheritAttrs: true,
})

const hasTabs = computed<boolean>(() => {
  return props.schema.fields.some((field) => field.fields)
})

const handleSubmit = (values: any) => {
  formErrors.value = []
  emit('submit', values)
}

const resetForm = (state) => {
  form.value?.resetForm(state)
  formErrors.value = []
}

const validationSchema = computed(() => {
  const rules = props.schema.fields.reduce((acc, field) => {
    if (field.fields) {
      field.fields.forEach((subField) => acc[subField.name] = subField.rules)
    } else {
      acc[field.name] = field.rules
    }

    return acc
  }, {})

  return yup.object().shape(rules)
})

const setErrors = (errors?: Record<string, string | string[]>) => {
  if (!errors) {
    formErrors.value.push('An unknown error has occurred.')
    return
  }

  Object.entries(errors).forEach(([name, messages]) => {
    if (['non_field_errors', 'detail'].includes(name)) {
      if (Array.isArray(messages)) {
        messages.forEach((message) => formErrors.value.push(message))
      } else {
        formErrors.value.push(messages)
      }
    } else {
      if (Array.isArray(messages)) {
        messages.forEach((message) => form.value?.setFieldError(name, message))
      } else {
        form.value?.setFieldError(name, messages)
      }
    }
  })
}

const setFieldValue = (field: string, value: any) => form.value?.setFieldValue(field, value)

defineExpose({ resetForm, setErrors, setFieldValue })
</script>
